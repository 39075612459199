<template>
    <div class="home">
        <div class="title">⚡ Prêmios <span>Escolha sua sorte</span></div>
        <div class="campaigns">
            <div class="campaign" v-for="(item, index) in products" :key="index" @click="toProduct(index)">
                <div class="image">
                    <img :src="`${apiURL}${item.cover}`" alt="">
                </div>
                <div class="info">
                    <div class="name">{{ item.name }}</div>
                    <div class="badge">Adquira Já!</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productService from '@/services/product-service';
import { mapGetters } from 'vuex';


export default {
    name: 'HomeView',
    data() {
        return {
            products: []
        }
    },
    computed: {
        ...mapGetters(["apiURL"])
    },
    methods: {
        async fetchProducts() {
            try {
                const response = await productService.list({
                    status: 'active'
                });

                this.products = response.data.products;
            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        },
        toProduct(index) {
            if (this.products[index]) {
                const product = this.products[index];

                return this.$router.push(`/sorteio/${product.slug}`)
            }
        }
    },
    mounted() {
        this.fetchProducts()
    }
}
</script>

<style lang="scss" scoped>
.home {
    background: var(--bg-color);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-top: -2rem;
    padding: 1rem;

    .title {
        text-align: left;
        font-size: 1.5rem;
        font-weight: 600;

        span {
            font-size: 1rem;
            font-weight: 300;
        }
    }

    .campaigns {
        padding: 1rem;

        .campaign {
            width: 100%;
            box-shadow: 0 4px 3px var(--border-color);
            display: flex;
            flex-direction: column;
            align-items: start;
            border-radius: 1rem;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 1rem;

            .image {
                height: 290px;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .info {
                width: calc(100% - 1rem);
                padding: .5rem;
                text-align: left;

                .name {
                    text-align: left;
                    font-weight: 600;
                    margin-bottom: .5rem;
                }
    
                .badge {
                    display: inline-block;
                    background: #198754;
                    color: #fff;
                    font-size: .7em;
                    font-weight: 700;
                    border-radius: .25rem;
                    padding: .35em .65em;
                    animation: blink 2s ease infinite;
                }
            }

        }
    }
}
</style>
