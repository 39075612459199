import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        showLogin: false,
        apiURL: "https://api.riflix.online",
        store: {
            name: null,
            theme: null,
            favicon: null,
            logo: null,
            token: null,
            active: false,
        },
        user: null
    },
    getters: {
        showLogin(state) {
            return state.showLogin
        },        
        apiURL(state) {
            return state.apiURL
        },        
        store(state) {
            return state.store
        },        
        user(state) {
            return state.user
        }
    },
    mutations: {
        setShowLogin(state, payload) {
            state.showLogin = payload
        },
        setStore(state, payload) {
            state.store = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
    },
    plugins: [
        createPersistedState({
            key: 'customer',
            storage: window.localStorage,
            paths: ['user'],
        }),
    ]
})
