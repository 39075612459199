<template>
    <header>
        <div class="container">
            <div class="logo" @click="this.$router.push('/')">
                <img alt="Logo" :src="(store && store.logo) ? `${apiURL}${store.logo}` : '../assets/logo.png'">
            </div>
            <div class="menu" @click="this.showMenu = true">
                <font-awesome-icon :icon="['fas', 'bars']" />
            </div>
        </div>
    </header>
    <menu-component v-if="this.showMenu" :callback="this.closeMenu" />
</template>

<script>
import { mapGetters } from 'vuex';
import MenuComponent from './MenuComponent.vue'

export default {
    name: "HeaderComponent",
    components: {
        MenuComponent
    },
    data() {
        return {
            showMenu: false
        }
    },
    computed: {
        ...mapGetters(["store", "apiURL"])
    },
    watch: {
        $route() {
            this.closeMenu();
        },
    },
    methods: {
        closeMenu() {
            this.showMenu = false;
        }
    }
}
</script>

<style lang="scss" scoped>
header {
    background: var(--text-color);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    color: var(--bg-color);
    padding: .5rem 0;

    .container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 700px !important;
        gap: 1.5rem;

        .logo {
            flex-grow: 1;
            text-align: left;

            img {
                max-height: 55px;
                max-width: 185px;
                cursor: pointer;
            }
        }

        .menu {
            font-size: 1.5rem;
            cursor: pointer;
            transition: all .5s;

            &:hover {
                opacity: .7;
            }
        }
    }
}
</style>