<template>
    <div class="change-theme">
        <div class="themes" v-if="this.showThemes">
            <div class="theme"  v-for="(item, index) in this.themes"  :key="index" @click="this.selectTheme(index)">
                {{ item.name }}
            </div>
        </div>
        <div class="icon" @click="this.showThemes = !this.showThemes">
            <font-awesome-icon :icon="['fas', 'palette']" v-if="!this.showThemes" />
            <font-awesome-icon :icon="['fas', 'xmark']" v-if="this.showThemes" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ChangeThemeComponent",
    data() {
        return {
            themes: [
                {
                    value: "theme-white",
                    name: "Branco"
                },
                {
                    value: "theme-black",
                    name: "Preto"
                },
                {
                    value: "theme-gray",
                    name: "Cinza"
                },
                {
                    value: "theme-green",
                    name: "Verde"
                }
            ],
            showThemes: false
        }
    },
    props: {
        callback: {
            type: Function,
            default: null
        }
    },
    methods: {
        selectTheme(index) {
            if (this.themes[index]) {
                const theme = this.themes[index];

                if (this.callback) {
                    this.callback(theme.value);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.change-theme {
    position: fixed;
    bottom: 20vh;
    right: 2vw;

    .themes {
        background: var(--text-color);
        color: var(--bg-color);
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        margin-bottom: .5rem;

        .theme {
            border-bottom: 1px solid var(--border-color);
            padding: .3rem 1rem;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                opacity: .7;
            }
        }
    }

    .icon {
        font-size: 1.5rem;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        padding: .5rem;
        background: var(--text-color);
        color: var(--bg-color);
        border-radius: 50%;
        cursor: pointer;
    }
}
</style>