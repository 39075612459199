import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from './plugins/font-awesome'
import Utils from './plugins/utils'
import { vMaska } from "maska/vue"

createApp(App)
    .use(store)
    .use(router)
    .use(Utils)
    .component("font-awesome-icon", FontAwesomeIcon)
    .directive("maska", vMaska)
    .mount('#app');