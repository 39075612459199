<template>
    <template v-if="!store.active">
        <h1 style="margin: 25rem 1rem 0;">Loja não ativada, contato o administrador</h1>
    </template>
    <template v-else>
        <header-component />
        <div class="fake_header"></div>
        <main>
            <router-view/>
        </main>
        <change-theme-component :callback="this.changeThemeCallback" v-show="false" />
        <footer-component />
        <login-component v-if="this.showLogin" />
        <support-component />
    </template>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import ChangeThemeComponent from './components/ChangeThemeComponent.vue'
import FooterComponent from './components/FooterComponent.vue';
import LoginComponent from './components/LoginComponent.vue';
import SupportComponent from './components/SupportComponent.vue';
import { mapGetters, mapMutations } from 'vuex';
import storeService from './services/store.service';

export default {
    name: "App",
    components: {
        HeaderComponent,
        ChangeThemeComponent,
        FooterComponent,
        LoginComponent,
        SupportComponent
    },
    data() {
        return {
            selectedTheme: 'theme-white'
        }
    },
    computed: {
        ...mapGetters(["showLogin", "store", "apiURL"])
    },
    watch: {
        selectedTheme(newTheme) {
            this.changeTheme(newTheme);
        }
    },
    methods: {
        ...mapMutations(["setStore"]),
        changeTheme() {
            document.body.className = '';
            document.body.classList.add(this.selectedTheme);
        },
        changeThemeCallback(value) {
            this.selectedTheme = value;
        },
        setFavicon(iconUrl) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = iconUrl;
        },
        async fetchStoreData() {
            try {
                const hostname = window.location.hostname;
                let store = null;

                if (hostname.endsWith(".riflix.online")) {
                    store = hostname;
                } else if (hostname === 'localhost') {
                    store = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiaWF0IjoxNzMxNjI3NTAzfQ.tIYj51toHREvE0UrB1fnVK5x0zCQZabErOlFgUft548';
                } else if (hostname === 'riflix.online') {
                    store = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzMyNjYwNzgxfQ.oSc7CtqUecUSLy4KsaS2hp2Cm7BvOmmJPcAqRPC1EyA';
                } else {
                    try {
                    const response = await storeService.records({
                        hostname: hostname,
                    });
                    
                    store = response.data.token;

                    } catch (error) {
                        console.error("Erro ao buscar o registro TXT:", error);
                    }
                }

                const tenantResponse = await storeService.read({ store: store });
                this.setStore(tenantResponse.data.store);

                this.setFavicon(`${this.apiURL}${this.store.favicon}` || '/favicon.png');
                document.title = this.store.name || 'Riflix Sorteios';

                this.selectedTheme = `theme-${this.store.theme}` || 'theme-white';
                this.changeTheme();

            } catch (error) {
                console.error("Erro ao buscar dados da loja:", error);
            }
        }
    },
    mounted() {
        this.fetchStoreData();
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    .fake_header {
        background: var(--text-color);
        width: 100%;
        height: 2rem;
    }

    main {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 700px !important;
        min-height: 70vh;
    }
}

.theme-white {
  --bg-color: #FFFFFF;
  --text-color: #333333;
  --border-color: #DDDDDD;
  --link-color: #007BFF;
}

.theme-black {
  --bg-color: #000000;
  --text-color: #FFFFFF;
  --border-color: #444444;
  --link-color: #FFD700;
}

.theme-gray {
  --bg-color: #F5F5F5;
  --text-color: #333333;
  --border-color: #B0B0B0;
  --link-color: #00BFFF;
}

.theme-green {
  --bg-color: #E8F5E9;
  --text-color: #2E7D32;
  --border-color: #81C784;
  --link-color: #4CAF50;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes loaderAnim{
  0%{
    transform: rotate(0);
  }
  50% {
    transform:rotate(180deg);
  }
  100%{
    transform:rotate(360deg);
  }
}
</style>
