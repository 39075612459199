import store from '../store';

export default function storeHeader() {
    const storeState = store.state.store;

    if (storeState && storeState.token) {
        return { 'x-store-token': storeState.token };
    } else {
        return {};
    }
}