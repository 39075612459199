import { toast } from 'vue3-toastify';
import "vue3-toastify/dist/index.css";

class Utils {
    static  generateImage(name, darkmode, background, color) {
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;

        const ctx = canvas.getContext('2d');

        if (!background) {
            background =  (darkmode) ? '#333': '#dbdbdb'
        }

        if (!color) {
            color =  (darkmode) ? '#fff': '#333'
        }

        ctx.fillStyle = background; // Cor preta
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Define o estilo do texto
        ctx.fillStyle = color; // Cor branca
        ctx.font = 'bold 4.8vh Arial'; // Tamanho e fonte do texto

        const namefiltered = this.filterName(name).toUpperCase();

        const textWidth = ctx.measureText(namefiltered).width;
        const textX = (canvas.width - textWidth) / 2;
        const textY = (canvas.height + 35) / 2; // 48 é o tamanho da fonte

        ctx.fillText(namefiltered, textX, textY);

        const dataUrl = canvas.toDataURL();

        return dataUrl
    }

    static filterName(fullName) {
        fullName = fullName.trim();

        const pattern = /^[A-Za-z]/;
        const words = fullName.split(' ');

        for (const word of words) {
            if (pattern.test(word)) {
                return word.charAt(0).toUpperCase();
            }
        }

        return '';
    }

    static formatDateAgo(dateString) {
        const date = new Date(dateString);
        const now = new Date();
        const diff = now - date;

        // Calcula o tempo em segundos
        const seconds = Math.floor(diff / 1000);

        if (seconds < 60) {
            return seconds === 1 ? "1 segundo atrás" : `${seconds} segundos atrás`;
        }

        // Calcula o tempo em minutos
        const minutes = Math.floor(seconds / 60);

        if (minutes < 60) {
            return minutes === 1 ? "1 minuto atrás" : `${minutes} minutos atrás`;
        }

        // Calcula o tempo em horas
        const hours = Math.floor(minutes / 60);

        if (hours < 24) {
            return hours === 1 ? "1 hora atrás" : `${hours} horas atrás`;
        }

        // Calcula o tempo em dias
        const days = Math.floor(hours / 24);

        if (days < 30) {
            return days === 1 ? "1 dia atrás" : `${days} dias atrás`;
        }

        // Calcula o tempo em meses
        const months = Math.floor(days / 30);

        if (months < 12) {
            return months === 1 ? "1 mês atrás" : `${months} meses atrás`;
        }

        // Calcula o tempo em anos
        const years = Math.floor(months / 12);
        return years === 1 ? "1 ano atrás" : `${years} anos atrás`;
    }
    
    static getFormatedTime(time) {
        let newtime = new Date(time);

        let hours = newtime.getHours();
        let minutes = newtime.getMinutes();

        if (hours < 10) {
            hours = "0" + hours
        }

        if (minutes < 10) {
            minutes = "0" + minutes
        }

        return `${hours}:${minutes}`
    }

    static formatNumberToK(number) {
        if (number >= 1000) {
          const formattedNumber = (number / 1000).toFixed(1);
          return `${formattedNumber}K`;
        }
        return number.toString();
    }

    static limitText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.substring(0, maxLength) + '...';
        }
    }
    
    static formartPrice(price){
        if (typeof(price) != "number") {
            price = parseInt(price)
        }
        
        return (price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        });
    }
    
    static formartNumber(price){
        if (typeof(price) != "number") {
            price = parseInt(price)
        }
        
        return (price).toLocaleString();
    }

    static formartDate(date, hour){
        if (date != null){
            var formatdate = new Date(date);
            if (hour) {
                return formatdate.toLocaleDateString('pt-BR') + " às " + formatdate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute:'2-digit' });
            }else{
                return formatdate.toLocaleDateString('pt-BR');
            }
        }else{
            return "Não encontrado"
        }
    }

    static dateFormatted(date) {
        var formatdate = new Date(date);
        var day = formatdate.getDate();
        var month = formatdate.getMonth() + 1;
        var year = formatdate.getFullYear();
        return `${year}-${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}`;
    }

    static dateFormattedMysql(date) {
        var formatdate = new Date(date);
        var day = formatdate.getDate();
        var month = formatdate.getMonth() + 1;
        var year = formatdate.getFullYear();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }

    static formatTime(secondsTotal) {
        const hours = Math.floor( secondsTotal / 3600);
        const minutesRemaining = secondsTotal  % 3600;
        const minutes = Math.floor(minutesRemaining / 60);
        const seconds = minutesRemaining % 60;
    
        function twoDigits(number) {
            return number.toString().padStart(2, '0');
        }
    
        if (hours > 0) {
            return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`;
        }
    
        return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
    }

    static formatTextLineBreaks(text) {
        return text.replace(/\n/g, "<br>")
    }

    static getFormatedMessage(message) {
        if (!message) {
            message = "Vazio";
        }

        if (message.includes('http://')) {
            message = "Link / Arquivo";
        }

        if (message.includes('https://')) {
            message = "Link / Arquivo";
        }

        if (message.includes('"x":')) {
            message = "Localização";
        }

        if (message.includes('"y":')) {
            message = "Localização";
        }

        if (message.includes('.webm')) {
            message = "Áudio";
        }

        if (message.length > 25) {
            message = `${message.substring(0, 25)}...`;
        }

        return message;
    }

    static rgbToHex(r, g, b) {
        const toHex = (c) => {
          const hex = c.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };
      
        const red = toHex(r);
        const green = toHex(g);
        const blue = toHex(b);
      
        return `#${red}${green}${blue}`;
    }

    static secondsToTime(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = parseInt(totalSeconds % 60);
      
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
      
        if (hours > 0) {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    }

    static getMonthAndDay(dateString) {
        const months = [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
            "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const monthName = months[date.getMonth()];

        return `${monthName} ${day}`;
    }

    static getHourAndMinutes(dateString) {
        const date = new Date(dateString);
        const hour = date.getHours();
        const minutes = date.getMinutes();

        return `${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
    }

    static isImageLink(link) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const filename = link.split('/').pop(); // Extrai o nome do arquivo do link
        const extension = filename.split('.').pop().toLowerCase(); // Extrai a extensão e converte para minúsculas
        return imageExtensions.includes(extension);
    }

    static isVideoLink(link) {
        const videoExtensions = ['webm', 'mp4', 'avi'];
        const filename = link.split('/').pop(); // Extrai o nome do arquivo do link
        const extension = filename.split('.').pop().toLowerCase(); // Extrai a extensão e converte para minúsculas
        return videoExtensions.includes(extension);
    }

    static maskCPF(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');
  
        // Formata o CPF: 000.***.***-**
        if (value.length >= 11) {
          return `${value.slice(0, 3)}.${'***'}.${'***'}-${value.slice(9)}`;
        }
        return value; // Retorna o valor original se não for um CPF válido
    }

    static maskPhone(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');
  
        // Formata o telefone: (00) 00000-****
        if (value.length >= 11) {
          return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${'****'}`;
        }
        return value; // Retorna o valor original se não for um telefone válido
    }

    static removeMask(value) {
        return value.replace(/\D/g, '');
    }

    static toast(message, options) {
        return toast(message, options)
    }

    static toastpromise(promise, params, options) {
        return toast.promise(promise, params, options)
    }
}

export default {
    install(app) {
        app.config.globalProperties.$utils = Utils;
    },
}