<template>
    <div class="menu">
        <div class="container">
            <div class="header">
                <div class="logo">
                    <img alt="Logo" src="../assets/logo.png">
                </div>
                <div class="close" @click="this.closeMenu">
                    <font-awesome-icon :icon="['far', 'circle-xmark']" />
                </div>
            </div>
            <div class="account" v-if="this.user">
                <div class="image">
                    <img :src="this.getImage()" alt="user">
                </div>
                <div class="info">
                    <div class="name">{{ this.user.name }}</div>
                    <div class="text" v-html="this.$utils.maskCPF(this.user.cpf)"></div>
                </div>
            </div>
            <div class="menu_list">
                <div class="item" 
                    v-for="(item, index) in this.menu" 
                    :key="index" 
                    @click="this.selectMenu(index)"
                    v-show="this.checkMenu(index)"
                >
                    <div class="icon">
                        <font-awesome-icon :icon="item.icon" />
                    </div>
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
            <div class="button" @click="this.setShowLogin(true)" v-if="!this.user">
                <font-awesome-icon :icon="['fas', 'arrow-right-to-bracket']" /> Entrar
            </div>
            <div class="button" @click="handleLogout" v-if="this.user">
                <font-awesome-icon :icon="['fas', 'arrow-right-to-bracket']" /> Sair
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "MenuComponent",
    data() {
        return {
            isLogged: false,
            menu: [
                {
                    icon: ['fas', 'house'],
                    name: "Inicio",
                    link: "/"
                },
                {
                    icon: ['fas', 'list'],
                    name: "Campanhas",
                    link: "/sorteios"
                },
                {
                    icon: ['fas', 'list-ol'],
                    name: "Meus Números",
                    link: "/meus-numeros"
                },
                {
                    icon: ['fas', 'right-to-bracket'],
                    name: "Cadastro",
                    link: "/cadastro",
                    isvalidate: true,
                    validate: false
                },
                {
                    icon: ['fas', 'circle-user'],
                    name: "Atualizar Cadastro",
                    link: "/conta",
                    isvalidate: true,
                    validate: true
                },
                {
                    icon: ['fas', 'trophy'],
                    name: "Ganhadores",
                    link: "/ganhadores"
                },
                {
                    icon: ['fas', 'file-lines'],
                    name: "Termos de Uso",
                    link: "/termos"
                },
                {
                    icon: ['fas', 'envelope'],
                    name: "Contato",
                    link: "/contato"
                }
            ]
        }
    },
    props: {
        callback: {
            type: Function,
            default: null
        }
    },
    computed: {
        ...mapGetters(["user"])
    },
    methods: {
        ...mapMutations(["setShowLogin", "setUser"]),
        closeMenu() {
            if (this.callback) {
                this.callback()
            }
        },
        selectMenu(index) {
            if (this.menu[index]) {
                const menu = this.menu[index]

                return this.$router.push(menu.link);
            }
        },
        getImage() {
            return this.$utils.generateImage("Felipe Bruno", false);
        },
        checkMenu(index) {
            if (this.menu[index]) {
                const item = this.menu[index];
                if (item.isvalidate) {
                    if (item.validate) {
                        return (this.user !== null) ? true : false
                    } else {
                        return (this.user === null) ? true : false
                    }
                } else {
                    return true
                }
            }
        },
        handleLogout() {
            this.setUser(null);
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    background: var(--text-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    color: var(--bg-color);

    .container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 600px !important;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0;

            .logo {
                flex-grow: 1;
                text-align: left;

                img {
                    max-height: 55px;
                    max-width: 185px;
                    cursor: pointer;
                }
            }

            .close {
                font-size: 2rem;
                cursor: pointer;
                transition: all .5s;

                &:hover {
                    opacity: .7;
                }
            }
        }

        .account {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;

            .image {
                width: 4rem;
                height: 4rem;
                overflow: hidden;
                border-radius: .5rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .info {
                text-align: left;

                .name {
                    font-size: 1.2rem;
                    font-weight: 800;
                }

                .text {
                    font-size: .8rem;
                    font-weight: 300;
                }
            }
        }

        .menu_list {
            .item {
                display: flex;
                align-items: center;
                gap: 1rem;
                border-top: 1px solid var(--border-color);
                padding: .5rem;
                cursor: pointer;

                &:last-child {
                    border-bottom: 1px solid var(--border-color);
                }

                &:hover {
                    opacity: .7;
                }

                .icon {
                    font-size: 1.5rem;
                }
            }
        }

        .button {
            background: var(--link-color);
            color: #fff;
            font-weight: bold;
            width: calc(100% - 2rem);
            padding: 1rem;
            border-radius: 2rem;
            margin-top: .5rem;
            cursor: pointer;
            transition: all .5s;

            &:hover {
                opacity: .8;
            }
        }
    }
}
</style>