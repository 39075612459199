<template>
    <footer>
        <div class="container">
            <div class="logo">
                <img alt="Logo" :src="(store && store.logo) ? `${apiURL}${store.logo}` : '../assets/logo.png'">
            </div>
            <div class="info">
                <div class="left">
                    <div class="text">
                        O Riflix é uma plataforma de sorteios vinculada as lótericas caixa com regulamento N° 154878
                    </div>
                    <div class="links">
                        <div class="link" @click="this.$router.push('/termos')">Termos de Uso</div>
                        <div class="link" @click="this.$router.push('/privacidade')">Politica de Privacidade</div>
                    </div>
                </div>
                <div class="social" v-if="store && store.social && Object.keys(store.social).length > 0">
                    <div class="caption">Nossas Redes sociais</div>
                    <div class="items">
                        <div class="item facebook" v-if="store.social.facebook" @click="toLinkSocial(store.social.facebook.link)"><font-awesome-icon :icon="['fab', 'facebook']" /></div>
                        <div class="item whatsapp" v-if="store.social.whatsapp" @click="toLinkSocial(store.social.whatsapp.link)"><font-awesome-icon :icon="['fab', 'whatsapp']" /></div>
                        <div class="item instagram" v-if="store.social.instagram" @click="toLinkSocial(store.social.instagram.link)"><font-awesome-icon :icon="['fab', 'instagram']" /></div>
                        <div class="item youtube" v-if="store.social.youtube" @click="toLinkSocial(store.social.youtube.link)"><font-awesome-icon :icon="['fab', 'youtube']" /></div>
                    </div>
                </div>
            </div>
            <div class="border"></div>
            <div class="regulation">
                Este site e os produtos e serviços oferecidos neste site não são associados, afiliados, endossados ou patrocinados pelo Facebook, nem foram revisados, testados ou certificados pelo Facebook. O nome e o logotipo do Facebook são marcas registradas do Facebook, Inc.
            </div>
            <div class="border"></div>
            <div class="copyright">
                <p>Riflix.com © 2024 - Todos os direitos reservados</p>
                <p>Desenvolvido por <span @click="toRiflix">www.riflix.online</span></p>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "FooterComponent",
    computed: {
        ...mapGetters(["store", "apiURL"])
    },
    methods: {
        toLinkSocial(link) {
            window.open(link, '_blank')
        },
        toRiflix() {
            window.open('https://riflix.online', '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    width: calc(100% - 2rem);
    padding: 1rem;
    background: var(--text-color);
    color: var(--bg-color);

    .container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 700px !important;
        position: relative;

        .logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 20%;
            margin: 0 auto;

            img {
                max-height: 55px;
                max-width: 185px;
            }
        }

        .info {
            display: flex;
            align-items: start;
            justify-content: space-between;
            margin-top: 1rem;

            .left {
                .text {
                    font-size: .7rem;
                    width: 45%;
                    text-align: left;
                }

                .links {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    margin-top: 1rem;
    
                    .link {
                        text-decoration: underline;
                        font-size: .7rem;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .social {
                text-align: right;
    
                .caption {
                    font-size: .8rem;
                }
    
                .items {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-top: .5rem;
    
                    .item {
                        font-size: 1.5rem;
                        cursor: pointer;

                        &.facebook:hover {
                            color: #1877F2;
                        }

                        &.whatsapp:hover {
                            color: #25D366;
                        }

                        &.instagram:hover {
                            color: #E1306C;
                        }

                        &.youtube:hover {
                            color: #FF0000;
                        }
                    }
                }
            }
        }

        .border {
            margin-top: 1rem;
            border-top: 1px solid var(--border-color);
        }

        .regulation {
            font-size: .7rem;
            width: 80%;
            margin: 0 auto;
            margin-top: 1rem;
        }

        .copyright {
            font-size: .7rem;
            margin-top: 1rem;
            
            span {
                color: var(--link-color);
                cursor: pointer;

                &:hover {
                    font-size: .9rem;
                }
            }
        }

    }
}
</style>