import store from '../store';

export default function authHeader() {
    const user = store.state.user;
  
    if (user && user.accessToken) {
        return { 'x-access-token': user.accessToken };
    } else {
        return {};
    }
}