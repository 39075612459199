<template>
    <div class="login">
        <div class="container">
            <div class="title" v-if="!this.isSignup"><font-awesome-icon :icon="['fas', 'key']" /> Login</div>
            <div class="title" v-if="this.isSignup"><font-awesome-icon :icon="['fas', 'key']" /> Cadastro</div>
            <div class="close" @click="this.setShowLogin(false)"><font-awesome-icon :icon="['fas', 'xmark']" /></div>
            <Form @submit="handleLogin" :validation-schema="loginSchema" class="form" v-if="!this.isSignup">
                <div class="row">
                    <div class="caption">CPF *</div>
                    <div class="input">
                        <Field type="text" id="cpf" name="cpf" v-maska data-maska="['###.###.###-##']" v-model="cpf" placeholder="000.000.000-00" />
                        <ErrorMessage name="cpf" class="text-danger" />
                    </div>
                </div>
                <button 
                    type="submit"
                    class="button"
                    :disabled="loading"
                >
                    <font-awesome-icon :icon="['fas', 'circle-notch']" spin v-if="loading" />
                    <font-awesome-icon :icon="['fas', 'right-to-bracket']" v-if="!loading"/>
                    Continuar
                </button>
            </Form>
            <Form @submit="handleRegister" :validation-schema="registerSchema" class="form" v-if="this.isSignup">
                <div class="row">
                    <div class="caption">CPF *</div>
                    <div class="input">
                        <Field type="text" id="cpf" name="cpf" v-maska data-maska="['###.###.###-##']" v-model="cpf" placeholder="000.000.000-00" />
                        <ErrorMessage name="cpf" class="text-danger" />
                    </div>
                </div>
                <div class="row">
                    <div class="caption">Nome *</div>
                    <div class="input">
                        <Field type="text" id="firstname" name="firstname" v-model="firstname" />
                        <ErrorMessage name="firstname" class="text-danger" />
                    </div>
                </div>
                <div class="row">
                    <div class="caption">Sobrenome *</div>
                    <div class="input">
                        <Field type="text" id="lastname" name="lastname" v-model="lastname" />
                        <ErrorMessage name="lastname" class="text-danger" />
                    </div>
                </div>
                <div class="row">
                    <div class="caption">Telefone *</div>
                    <div class="input">
                        <Field type="text" id="phone" name="phone" v-maska data-maska="['(##) #####-####']" v-model="phone" placeholder="(00) 00000-0000" />
                        <ErrorMessage name="phone" class="text-danger" />
                    </div>
                </div>
                <div class="row">
                    <div class="caption">Email *</div>
                    <div class="input">
                        <Field type="email" id="email" name="email" v-model="email" />
                        <ErrorMessage name="email" class="text-danger" />
                    </div>
                </div>
                <div class="row">
                    <div class="caption">CEP *</div>
                    <div class="input">
                        <Field type="text" id="cep" name="cep" v-maska data-maska="['#####-###']" v-model="cep" @change="this.changeCEP" placeholder="00000-000"/>
                        <ErrorMessage name="cep" class="text-danger" />
                    </div>
                </div>
                <button 
                    type="submit"
                    class="button"
                    :disabled="loading"
                >
                    <font-awesome-icon :icon="['fas', 'circle-notch']" spin v-if="loading" />
                    <font-awesome-icon :icon="['fas', 'right-to-bracket']" v-if="!loading"/>
                    Cadastrar
                </button>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapMutations } from 'vuex';
import axios from 'axios';
import authService from "@/services/auth.service";

export default {
    name: "LoginComponent",
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            isSignup: false,
            cpf: null,
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            state: null,
            city: null,
            district: undefined,
            region: undefined,
            address: undefined,
            cep: undefined,
            loading: false,
            loginSchema: yup.object({
                cpf: yup
                    .string()
                    .required("O CPF é obrigatório")
                    .test("validar-cpf", "CPF inválido", this.validateCPF)
            }),
            registerSchema: yup.object({
                cpf: yup
                    .string()
                    .required("O CPF é obrigatório")
                    .test("validar-cpf", "CPF inválido", this.validateCPF),
                firstname: yup
                    .string()
                    .required("O nome é obrigatório!")
                    .min(3, "Deve ter pelo menos 3 caracteres!")
                    .max(50, "Deve ter no máximo 50 caracteres!"),
                lastname: yup
                    .string()
                    .required("O sobrenome é obrigatório!")
                    .min(3, "Deve ter pelo menos 3 caracteres!")
                    .max(50, "Deve ter no máximo 50 caracteres!"),
                phone: yup
                    .string()
                    .required("O telefone é obrigatório")
                    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, "Formato do telefone inválido"),
                email: yup
                    .string()
                    .email("Digite um email válido")
                    .required("O email é obrigatório")
                    .max(255, "Deve ter no máximo 255 caracteres!"),
                cep: yup
                    .string()
                    .required("O CEP é obrigatório")
                    .matches(/^\d{5}-\d{3}$/, "Formato do CEP inválido")
                
            })
        }
    },
    methods: {
        ...mapMutations(["setShowLogin","setUser"]),
        changeCEP() {
            var newcep = this.cep.replace(/[^0-9]/g,'');
            var url = `https://viacep.com.br/ws/${newcep}/json`

            axios.get(url)
            .then(response => {
                if (response.data){
                    this.state = response.data.uf
                    this.address = response.data.logradouro
                    this.district = response.data.bairro
                    this.region = response.data.regiao
                    this.city = response.data.localidade
                }
            })
        },
        validateCPF(cpf) {
            cpf = cpf.replace(/[^\d]+/g, '');
            if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
            let sum = 0, rest;

            for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            if (rest !== parseInt(cpf.substring(9, 10))) return false;

            sum = 0;
            for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            return rest === parseInt(cpf.substring(10, 11));
        },
        handleLogin(data) {
            data.cpf = this.$utils.removeMask(data.cpf);

            this.loading = true;
            authService.login(data).then(
                // eslint-disable-next-line no-unused-vars
                (response) => {
                    this.$utils.toast("Logado com sucesso!", {
                        theme: "auto",
                        type: "success"
                    });
                    this.loading = false;
                    this.setShowLogin(false);
                    this.setUser(response.data)
                },
                (error) => {
                    const message = error.response?.data?.message ?? error.message ?? error.toString();
                    this.loading = false;
                    this.$utils.toast(message, {
                        theme: "auto",
                        type: "error"
                    });
                    this.isSignup = true;
                }
            )
        },
        handleRegister(data) {
            data.cpf = this.$utils.removeMask(data.cpf);
            data.phone = this.$utils.removeMask(data.phone);
            data.cep = this.$utils.removeMask(data.cep);

            data.state = this.state;
            data.city = this.city;
            data.district = this.district;
            data.region = this.region;
            data.address = this.address;

            this.loading = true;
            authService.register(data).then(
                (response) => {
                    this.$utils.toast(response.data.message, {
                        theme: "auto",
                        type: "success"
                    });
                    this.loading = false;
                    this.setShowLogin(false);
                },
                (error) => {
                    const message = error.response?.data?.message ?? error.message ?? error.toString();
                    this.loading = false;
                    this.$utils.toast(message, {
                        theme: "auto",
                        type: "error"
                    });
                }
            )
        },
    }
}
</script>

<style lang="scss" scoped>
.login {
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;

    .container {
        width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;
        max-width: 600px !important;
        position: relative;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 2rem - 64px);
        background: var(--bg-color);
        color: var(--text-color);
        border-radius: 1rem;
        padding: 1rem;

        .title {
            text-align: left;
            font-size: 1.5rem;
        }

        .close {
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
            top: 1rem;
            right: 1rem;

            &:hover {
                opacity: .8;
            }
        }

        .form {
            margin-top: 1rem;
            width: 100%;

            .row {
                display: flex;
                flex-direction: column;
                gap: .2rem;
                text-align: left;
                margin-top: .5rem;

                .caption {
                    width: 100%;
                    font-weight: 600;
                }

                .input {
                    width: 100%;

                    input {
                        width: calc(100% - 2rem);
                        padding: 1rem;
                        border: 2px solid var(--border-color);
                        border-radius: 1rem;
                        outline: none;
                        appearance: none;
                        resize: none;
                    }

                    .text-danger {
                        color: red;
                        margin-top: .5rem;
                    }
                }
            }

            .button {
                width: 100%;
                padding: 1rem;
                border-radius: 1rem;
                background: var(--link-color);
                color: #fff;
                margin-top: 1rem;
                cursor: pointer;
                border: none;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}
</style>