import api from '@/api/axios';
import authHeader from './auth-header';
import storeHeader from './store-header';

class AuthService {
    login(data) {
        return api.post('customers/signin', data, { headers: storeHeader() });
    }

    register(data) {
        return api.post('customers/signup', data, { headers: storeHeader() });
    }

    uploadProfileImage(formData) {
        return api.post('customers/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader(),
                ...storeHeader()
            },
        }).then(response => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.image = response.data.image;
                localStorage.setItem('user', JSON.stringify(user));
            }
            return response.data;
        });
    }
}

export default new AuthService();