import api from "@/api/axios";
import storeHeader from "./store-header";

class ProductService {
    list(params) {
        return api.get("products/allfront", {
            params: params,
            headers: storeHeader()
        })
    }

    read(params) {
        return api.get("products/readfront", {
            params: params,
            headers: storeHeader()
        })
    }
}

export default new ProductService()