import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/termos',
        name: 'terms',
        component: () => import('../views/TermsView.vue')
    },
    {
        path: '/privacidade',
        name: 'privacy',
        component: () => import('../views/PrivacyView.vue')
    },
    {
        path: '/contato',
        name: 'contact',
        component: () => import('../views/ContactView.vue')
    },
    {
        path: '/sorteios',
        name: 'campaigns',
        component: () => import('../views/CampaignsView.vue')
    },
    {
        path: '/cadastro',
        name: 'register',
        component: () => import('../views/RegisterView.vue')
    },
    {
        path: '/meus-numeros',
        name: 'my-numbers',
        component: () => import('../views/MyNumbersView.vue')
    },
    {
        path: '/ganhadores',
        name: 'winners',
        component: () => import('../views/WinnersView.vue')
    },
    {
        path: '/sorteio/:slug',
        name: 'campaign',
        component: () => import('../views/CampaignView.vue')
    },
    {
        path: '/pagamento/:code',
        name: 'payment',
        component: () => import('../views/PaymentView.vue')
    },
    {
        path: '/compra/:code',
        name: 'buy',
        component: () => import('../views/BuyView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
