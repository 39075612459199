import api from "@/api/axios";

class StoreService {
    read(params) {
        return api.get("stores/readfront", {
            params: params
        })
    }

    records(params) {
        return api.get("stores/records", {
            params: params
        })
    }
}

export default new StoreService()