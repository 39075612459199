<template>
    <div class="support">
        <div class="icon">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
        </div>
        <div class="label">Suporte</div>
    </div>
</template>

<script>
export default {
    name: "SupportComponent"
}
</script>

<style lang="scss" scoped>
.support {
    position: fixed;
    bottom: 10vh;
    right: 2vw;

    .icon {
        font-size: 2rem;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        padding: .5rem;
        background: #25D366;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            opacity: .9;
        }
    }

    .label {
        margin-top: .5rem;
        background: var(--bg-color);
        color: var(--text-color);
        padding: .5rem;
        font-size: .8rem;
        border-radius: .5rem;
    }
}
</style>